<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        <feather-icon icon="PlusCircleIcon"/>
        {{i18nT(`Add new`)}}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendars</span>
        </h5>
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          {{i18nT(`All calendars`)}}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              name="event-filter"
              value="RE"
              class="mb-1 check-re"
            >
              Re
            </b-form-checkbox>
            <b-form-checkbox
              name="event-filter"
              value="ON"
              class="mb-1 check-on"
            >
              On
            </b-form-checkbox>
            <b-form-checkbox
              name="event-filter"
              value="HR"
              class="mb-1 check-hr"
            >
              Hr
            </b-form-checkbox>
            <b-form-checkbox
              name="event-filter"
              value="EN"
              class="mb-1 check-en"
            >
              En
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    }
  },
}
</script>

<style lang="scss">
.check-re {
  .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #5a5aff;
      background-color: #5a5aff;
  }
}
.check-on {
  .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #26a7ff;
      background-color: #26a7ff;
  }
}
.check-hr {
  .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #28c77a;
      background-color: #28c77a;
  }
}
.check-en {
  .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #ff9f43;
      background-color: #ff9f43;
  }
}
</style>
